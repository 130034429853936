
import { defineComponent, ComponentPublicInstance } from 'vue';
import { mapGetters } from 'vuex';
import Execution from '~/components/Execution/Execution.vue';
import i18n from '@/i18n'
export default defineComponent({
  name : 'TestPreview',
  props:{
    testId: String
  },
  computed:{
    ...mapGetters([
      'clientCountry',
    ]),  
  },
  data(){
    return{
      countFullScreen: 0,
      showPreview: false,
      dynamicStyles: {
        width: '90%',
        height: '90%',
        overflow: 'auto'
      }
    }
  },
  components:{
    Execution
  },
  methods:{
    closePreview(){
      this.notifyToExecution()
      this.showPreview = false
      i18n.global.locale = this.clientCountry
      const vm = this
      setTimeout(
      function() {
        vm.$emit('close')
      }, 500);
    },
    notifyToExecution() {
      const executionComponent = this.$refs.execution as ComponentPublicInstance | undefined;
      if (executionComponent) {
        (executionComponent as any).previewIsClosed();
      }
    },
    fullScreenPreview() {
      this.countFullScreen++;

      if (this.countFullScreen % 2 == 0) {
        this.dynamicStyles = {
          width: '90%',
          height: '90%',
          overflow: 'auto'
        }
      } else {
        this.dynamicStyles = {
          width: '100%',
          height: '100%',
          overflow: 'hidden'
        }
      }
    }
  },
  mounted(){
    this.showPreview = true
  }
})
